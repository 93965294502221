import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Moment from 'react-moment';
import {
  PageHeader,
  Divider,
  List,
  Tooltip,
  Spin,
  Button,
  Select,
  DatePicker,
} from 'antd';
import { userApi } from 'farmx-api';
import InfiniteScroll from 'react-infinite-scroller';
import { FaSyncAlt } from 'react-icons/fa';
import { BlockSelect, SensorSelect } from 'farmx-web-ui';

import ActiveTag from './ActiveTag';
import EventTypeTag from './EventTypeTag';
import EventCategoryTag from './EventCategoryTag';
import EventLogLevelIcon from './EventLogLevelIcon';
import LogLevelSelect from './LogLevelSelect';
import EventTypeSelect from './EventTypeSelect';
import EventCategorySelect from './EventCategorySelect';
import BlockTag from './BlockTag';

import './EventsPage.css';

export function EventsPage() {
  const location = useLocation();
  const history = useHistory();
  const { search, path } = location;

  // remove url parameters after processing them
  // history.replace(path);

  const { t } = useTranslation();

  // loading is used for any data loading
  const [loading, setLoading] = useState(false);
  // refreshing is used for reloading list from start
  const [refreshing, setRefreshing] = useState(false);
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  // filters
  const [block, setBlock] = useState(null);
  const [logLevel, setLogLevel] = useState(null);
  const [sensor, setSensor] = useState(null);
  const sensorIdentifier = sensor && sensor.identifier;
  const sensorType = sensor && sensor.type;
  const [active, setActive] = useState(null);
  const [eventCategory, setEventCategory] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [dateStart, dateEnd] = dateRange || [];
  const [cancelToken, setCancelToken] = useState(null);

  const hasMore = nextPage !== null;

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const defaultActive = urlParams.get('active') ? urlParams.get('active') === 'true' : null;
    const defaultBlock = urlParams.get('blockId');
    const defaultBlocks = defaultBlock ? [Number(defaultBlock)] : null;
    const defaultLogLevel = urlParams.get('logLevel');
    const defaultSensorIdentifier = urlParams.get('sensorIdentifier');
    const defaultSensorType = urlParams.get('sensorType');
    const defaultSensor = defaultSensorIdentifier && defaultSensorType && {
      identifier: defaultSensorIdentifier,
      type: defaultSensorType,
    };
    defaultActive && setActive(defaultActive);
    defaultLogLevel && setLogLevel(defaultLogLevel);
    defaultBlocks && setBlock(defaultBlocks);
    defaultSensor && setSensor(defaultSensor);
    history.replace(path);
  }, [search, history, path]);

  const refresh = useCallback(() => {
    // if (loading) return;
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);

    setRefreshing(true);
    setLoading(true);
    const params = {
      blockId: block,
      sensorType,
      sensorIdentifier,
      logLevel,
      active,
      eventCategory,
      eventType,
      dateStart,
      dateEnd,
      cancelToken: source.token,
    };
    userApi.loadUserEvents(params)
      .then((response) => {
        const { data } = response;
        const { results, count, next } = data;
        setEvents(results);
        setRefreshing(false);
        setLoading(false);
        setTotalEvents(count);
        if (next) {
          setNextPage(next);
        } else {
          setNextPage(undefined);
        }
      });
  }, [cancelToken, block, sensorType, sensorIdentifier,
    logLevel, active, eventCategory, eventType, dateStart, dateEnd]);

  const handleInfiniteOnLoad = useCallback(() => {
    if (!nextPage) return;
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);
    setLoading(true);
    const searchParams = new URLSearchParams(nextPage.split('?')[1]);
    const cursor = searchParams.get('cursor');
    userApi.loadUserEvents({
      cursor,
      blockId: block,
      sensorType,
      sensorIdentifier,
      logLevel,
      active,
      eventCategory,
      eventType,
      dateStart,
      dateEnd,
      cancelToken: source.token,
    }).then((response) => {
      const { data } = response;
      const { results, count, next } = data;
      setEvents(events.concat(results));
      setLoading(false);
      setTotalEvents(count);
      if (next) {
        setNextPage(next);
      } else {
        setNextPage(undefined);
      }
    });
  }, [cancelToken, nextPage, events, block, sensorType, sensorIdentifier,
    logLevel, active, eventCategory, eventType, dateStart, dateEnd]);

  // NOTE: this should handle initial load
  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, sensorType, sensorIdentifier, logLevel, active,
    eventCategory, eventType, dateStart, dateEnd]);

  function renderTotalEvents() {
    if (totalEvents !== null) {
      return `Loaded ${events.length} of ${totalEvents}`;
    }
    return null;
  }

  function getTitle() {
    return [
      t('Events'),
      <Button key="refresh" type="link" onClick={refresh} disabled={loading}><FaSyncAlt className={loading ? 'fa-spin' : ''} /></Button>,
      // renderTotalEvents(),
    ];
  }

  function renderHeaderExtra() {
    return renderTotalEvents();
  }

  function renderFooter() {
    return null;
  }

  function renderAvatar(event) {
    return <EventLogLevelIcon value={event.logLevel} />;
  }

  function renderDate(event) {
    return (
      <Tooltip title={<Moment format="YYYY-MM-DD h:mm:ss A" local>{event.createdAt}</Moment>} placement="bottom">
        <span>
          <Moment fromNow>{event.createdAt}</Moment>
          {' - '}
          <Moment format="YYYY-MM-DD h:mma">{event.createdAt}</Moment>
        </span>
      </Tooltip>
    );
  }

  function renderFilters() {
    return (
      <div className="event-list-filters">
        <BlockSelect
          className="block-select"
          placeholder={t('Block')}
          onChange={(b) => setBlock(b)}
          value={block}
        />
        <SensorSelect
          sensorType={sensorType}
          sensorIdentifier={sensorIdentifier}
          onChange={setSensor}
          placeholder={t('Sensor')}
        />
        <LogLevelSelect
          value={logLevel}
          placeholder={t('Severity')}
          onChange={(value) => setLogLevel(value)}
        />
        <EventCategorySelect
          value={eventCategory}
          placeholder={t('Category')}
          onChange={(value) => setEventCategory(value)}
        />
        <EventTypeSelect
          value={eventType}
          placeholder={t('Type')}
          onChange={(value) => setEventType(value)}
        />
        <Select
          placeholder={t('Active')}
          options={[{ value: true, label: t('Active') }, { value: false, label: t('Inactive') }]}
          value={active}
          allowClear
          onChange={setActive}
        />
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(d) => setDateRange(d)}
          value={dateRange}
          onOk={null}
          // style={{ width: '100%' }}
        />
      </div>
    );
  }

  function renderExtra(event) {
    return (
      <>
        <EventCategoryTag value={event.eventCategory} />
        <EventTypeTag value={event.eventType} />
        <ActiveTag value={event.active} />
      </>
    );
  }

  function renderEvent(event) {
    return (
      <List.Item
        key={event.id}
        // actions={}
        extra={renderExtra(event)}
      >
        <List.Item.Meta
          avatar={renderAvatar(event)}
          title={(
            <div className="event-description">
              <span>{event.description}</span>
              <BlockTag blockId={event.block} />
            </div>
          )}
          description={renderDate(event)}
        />
      </List.Item>
    );
  }

  return (
    <div className="scrollable-page single-column-page padded">
      <Helmet>
        <title>{t('Events')}</title>
      </Helmet>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        <PageHeader
          title={getTitle()}
          extra={renderHeaderExtra()}
        />
        {renderFilters()}
        <Divider />
        <div className="event-list-container">
          <div className="event-list">

            <List
              itemLayout="vertical"
              size="large"
              dataSource={events}
              loading={refreshing}
              footer={renderFooter()}
              renderItem={(event) => renderEvent(event)}
            >
              {loading && hasMore && (
              <div className="loading-container">
                <Spin />
              </div>
              )}
            </List>
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
