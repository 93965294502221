import {
  UserInfoPage,
  ChangePasswordPage,
  NotificationSettingsPage,
} from './components';

export const ROUTE_KEYS = {
  USER_INFO: '/settings/user',
  CHANGE_PASSWORD: '/settings/password',
  NOTIFICATION: '/settings/notifications',
};

export const ROUTES = {
  USER_INFO: {
    component: UserInfoPage,
    exact: true,
    key: ROUTE_KEYS.USER_INFO,
    path: ROUTE_KEYS.USER_INFO,
  },
  CHANGE_PASSWORD: {
    component: ChangePasswordPage,
    exact: true,
    key: ROUTE_KEYS.CHANGE_PASSWORD,
    path: ROUTE_KEYS.CHANGE_PASSWORD,
  },
  NOTIFICATION: {
    component: NotificationSettingsPage,
    exact: true,
    key: ROUTE_KEYS.NOTIFICATION,
    path: ROUTE_KEYS.NOTIFICATION,
  },
};
