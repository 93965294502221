import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Select,
} from 'antd';

export default function EventCategorySelect({ onChange, value, placeholder }) {
  const { t } = useTranslation();
  const options = [
    {
      value: 'control',
      label: t('Control'),
    },
    {
      value: 'data',
      label: t('Data'),
    },
    {
      value: 'network',
      label: t('Network'),
    },
  ];

  return (
    <Select
      value={value}
      placeholder={placeholder || t('Event Category')}
      options={options}
      onChange={onChange}
      allowClear
      dropdownMatchSelectWidth={false}
    />
  );
}

EventCategorySelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf([null, undefined, 'control', 'data', 'network']),
  placeholder: PropTypes.string,
};

EventCategorySelect.defaultProps = {
  onChange: () => {},
  value: null,
  placeholder: null,
};
