import React, { useState, useEffect, useRef } from 'react';
import {
  Switch, Redirect, Route, useLocation,
} from 'react-router-dom';
import { AppTemplate, PrivateRoute, UserMenu } from 'farmx-web-ui';
import { authService } from 'farmx-api';
import { hooks } from 'farmx-redux-core';
import { UserNotificationDropdown } from '../stream';
import Page404 from './Page404';
// import Sidebar from './Sidebar';
import EntitySideBar from './EntitySideBar';
import NavBarComponent from './NavBarComponent';
import FarmXLogo from '../../assets/images/farmx_logo_color_40.png';
import { ROUTES } from './routes';

const {
  useSetURLFromState,
  useSetStateFromURL,
} = hooks;

export default function Main() {
  // TODO: refactor the below to a custom hook
  const [userInfo, setUserUnfo] = useState();
  const location = useLocation();
  const { search, pathname } = location;
  const urlSearch = useRef(search);

  const state = useSetStateFromURL(urlSearch);
  const url = useSetURLFromState(urlSearch, search, pathname);

  useEffect(() => {
    authService.getUserInfo().then(setUserUnfo);
  }, [setUserUnfo]);

  return (
    <AppTemplate
      navBarComponent={NavBarComponent}
      entitySideBarComponent={EntitySideBar}
      headerMenu={(
        <div className="user-menu-wrapper">
          <UserNotificationDropdown userInfo={userInfo} />
          <UserMenu userInfo={userInfo} />
        </div>
      )}
      logo={FarmXLogo}
      title="FarmX"
      theme="light"
    >
      <Switch>
        <Route exact path="/">
          <Redirect to={{ pathname: '/control' }} />
        </Route>
        {Object.values(ROUTES).map(({
          component,
          exact,
          key,
          path,
        }) => (
          <PrivateRoute
            adminRequired={false}
            authService={authService}
            component={component}
            exact={exact}
            key={key}
            path={path}
          />
        ))}
        <PrivateRoute
          adminRequired={false}
          authService={authService}
          component={Page404}
        />
      </Switch>
    </AppTemplate>
  );
}
