import React from 'react';
import { matchPath } from 'react-router';
import { Link, withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import {
  FaCalendarAlt,
  FaGlobeAmericas,
  FaChartLine,
  FaPowerOff,
  FaTachometerAlt,
  FaCamera,
} from 'react-icons/fa';
import { ROUTES } from './routes';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';

const {
  getSelectedBlock,
  getSelectedRanch,
} = selectors;
const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

function NavBarComponent(props) {
  const location = useLocation();
  const {
    isMobile,
    onCollapse,
  } = props;

  // Get selected ranch and block
  const selectedRanch = useSelector((state) => getSelectedRanch(state));
  const selectedBlock = useSelector((state) => getSelectedBlock(state));

  let searchStr = '';
  if (selectedRanch && selectedRanch.payload && selectedRanch.payload[0]
      && !selectedBlock.payload.length) {
    searchStr = `?ranchId=${selectedRanch.payload[0]}`;
  } else if (selectedRanch && selectedRanch.payload && selectedRanch.payload[0]
      && selectedBlock && selectedBlock.payload && selectedBlock.payload[0]) {
    searchStr = `?ranchId=${selectedRanch.payload[0]}&blockId=${selectedBlock.payload[0]}`;
  }

  function collapseIfMobile() {
    if (isMobile && onCollapse) onCollapse();
    return null;
  }

  return (
    <Menu className="tab-menu" theme="light" selectedKeys={[getMatchedKey(location)]} mode="horizontal" onClick={collapseIfMobile}>
      <Menu.Item key="map">
        <a href={`https://app.farmx.co/home/map${searchStr}`}>
          <span className="menu-icon"><FaGlobeAmericas /></span>
          <span className="menu-title">MAP</span>
        </a>
      </Menu.Item>
      <Menu.Item key="graph">
        <a href={`https://app.farmx.co/home/graph${searchStr}`}>
          <span className="menu-icon"><FaChartLine /></span>
          <span className="menu-title">GRAPH</span>
        </a>
      </Menu.Item>
      <Menu.Item key="dashboard">
        <a href={`https://app.farmx.co/home/dashboard${searchStr}`}>
          <span className="menu-icon"><FaTachometerAlt /></span>
          <span className="menu-title">DASHBOARD</span>
        </a>
      </Menu.Item>
      <Menu.Item key={ROUTES.SCHEDULE.key}>
        <Link to={ROUTES.SCHEDULE.path}>
          <span className="menu-icon"><FaCalendarAlt /></span>
          <span className="menu-title">SCHEDULE</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.CONTROL.key}>
        <Link to={ROUTES.CONTROL.path}>
          <span className="menu-icon"><FaPowerOff /></span>
          <span className="menu-title">CONTROL</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="imaging">
        <a href={`https://app.farmx.co/home/imaging${searchStr}`}>
          <span className="menu-icon"><FaCamera /></span>
          <span className="menu-title">IMAGING</span>
        </a>
      </Menu.Item>
      <Menu.Item key={ROUTES.DASHBOARD.key}>
        <Link to={ROUTES.DASHBOARD.path}>
          <span className="menu-icon"><FaTachometerAlt /></span>
          <span className="menu-title">NEW DASHBOARD</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
}

NavBarComponent.propTypes = {
  isMobile: PropTypes.bool,
  onCollapse: PropTypes.func,
};

NavBarComponent.defaultProps = {
  isMobile: false,
  onCollapse: undefined,
};

export default withRouter(NavBarComponent);
