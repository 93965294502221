import React from 'react';
import { matchPath } from 'react-router';
import { Layout, PageHeader, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Link, Switch, Route, Redirect, withRouter, useLocation,
} from 'react-router-dom';
import {
  FaUser,
  FaLock,
  FaPaperPlane,
} from 'react-icons/fa';
import { useWindowSize } from 'farmx-web-ui';
import { ROUTES } from '../routes';
import './SettingsPage.css';

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

function SettingsPage() {
  const { t } = useTranslation();
  const rootPath = '/settings';
  const location = useLocation();
  const size = useWindowSize();

  function renderMenu() {
    return (
      <Menu
        className="settings-menu"
        theme="light"
        selectedKeys={[getMatchedKey(location)]}
        mode="inline"
        inlineCollapsed={size.width < 800}
      >
        <Menu.Item key={ROUTES.USER_INFO.key}>
          <Link to={ROUTES.USER_INFO.path}>
            <FaUser />
            <span className="menu-title">{t('User Info')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.CHANGE_PASSWORD.key}>
          <Link to={ROUTES.CHANGE_PASSWORD.path}>
            <FaLock />
            <span className="menu-title">{t('Change Password')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.NOTIFICATION.key}>
          <Link to={ROUTES.NOTIFICATION.path}>
            <FaPaperPlane />
            <span className="menu-title">{t('Notifications')}</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Layout className="single-column-page padded">
      <PageHeader title={t('Settings')} />
      <div className="settings-page">
        { renderMenu() }
        <div className="settings-body">
          <Switch>
            <Route exact path={rootPath}>
              <Redirect to={ROUTES.USER_INFO.path} />
            </Route>
            {Object.values(ROUTES).map(({
              component,
              exact,
              key,
              path,
            }) => (
              <Route
                component={component}
                exact={exact}
                key={key}
                path={path}
              />
            ))}
            <Redirect to={rootPath} />
          </Switch>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(SettingsPage);
