import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Tooltip,
  // Dropdown,
  // Badge,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  StreamApp,
  InfiniteScrollPaginator,
  NotificationDropdown,
  // NotificationFeed,
  // FeedContext,
  // Feed,
} from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';
import { authService } from 'farmx-api';
import { FaBell, FaCog } from 'react-icons/fa';
// import { SettingFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import NotificationGroupFlat from './NotificationGroupFlat';
import './UserNotificationDropdown.css';

export function UserNotificationDropdown({ userInfo }) {
  const { REACT_APP_STREAM_API_KEY, REACT_APP_STREAM_APP_ID } = process.env;
  const userStreamToken = authService.useStream();
  const { t } = useTranslation();

  if (userStreamToken === undefined || !userInfo) return null;
  if (!userStreamToken) return null;

  const userId = userInfo.user_id;
  const feedGroup = 'notification';

  function renderHeader() {
    return (
      <div className="notification-dropdown-header">
        <div className="items-left">
          <h4>{t('Notifications')}</h4>
          <Link to="/events">
            <Tooltip title={t('View Events')} placement="bottom">
              <Button>{t('View all')}</Button>
            </Tooltip>
          </Link>
        </div>
        <Link to="/settings/notifications">
          <Tooltip title={t('Notification Settings')} placement="bottom">
            <Button type="link"><FaCog size={20} /></Button>
          </Tooltip>
        </Link>
      </div>
    );
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <StreamApp
        apiKey={REACT_APP_STREAM_API_KEY}
        appId={REACT_APP_STREAM_APP_ID}
        token={userStreamToken}
      >
        <div className="notification-dropdown">
          <NotificationDropdown
            feedGroup={feedGroup}
            userId={userId}
            Icon={<FaBell size={20} />}
            Paginator={InfiniteScrollPaginator}
            Header={renderHeader()}
            Group={(props) => (
              <NotificationGroupFlat
                {...props}
              />
            )}
            right
          />
        </div>
      </StreamApp>
    </ErrorBoundary>
  );
}
