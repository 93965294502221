import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import {
  Checkbox,
  DatePicker,
  Radio, Button,
  PageHeader,
  Tag,
  Modal,
  Divider,
  Alert,
} from 'antd';
import {
  ReloadOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IrrigationSchedule,
  BlockSelect,
  ScheduleCreateForm,
  ScheduleUpdateForm,
} from 'farmx-web-ui';
import './mediaQuery.css';

const {
  setSelectedBlocks,
  setBlocks,
  setUrlLoad,
} = actions;

const {
  getSelectedBlocks,
} = selectors;

const {
  useSetURLFromState,
} = hooks;
function IrrigationSchedulePage() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const blocksFromState = useSelector((state) => getSelectedBlocks(state));
  const { state, search, pathname } = location;
  const { t } = useTranslation();
  const refresh = useRef(false); // To trigger the schedule api calls in IrrigationSchedule
  const blocks = blocksFromState.payload;

  const urlSearch = useRef(search);

  // This will be removed after testing
  // const updateURL = useRef(true);
  // /**
  //  * If no blocks are selected and no blockId param in URL
  //  * No need to update the URL
  //  */
  // if (blocks && !blocks.length && urlSearch.current
  //   && !urlSearch.current.includes('blockId')) updateURL.current = false;
  // /**
  //  * After clearing the selected blocks on schedulepage
  //  * Should update the URL
  //  */
  // else if (blocks && !blocks.length && urlSearch.current
  //   && urlSearch.current.includes('blockId')) updateURL.current = true;
  // /**
  //  * If no blocks are selected and none are set in URL parameters
  //  * Should update the URL with default values
  //  */
  // else if (blocks && !blocks.length && !urlSearch.current) updateURL.current = true;
  // /**
  //  * If block/blocks are selected from EntitySideBar/Schedule page
  //  * and no blockId param in URL
  //  * Should update the URL with selected values
  //  */
  // else if (blocks && blocks.length && urlSearch.current
  //   && !urlSearch.current.includes('blockId')) updateURL.current = true;
  // /**
  //  * If block/blocks are selected from EntitySideBar/Schedule page
  //  * AND the blockIds form both URL and history location
  //  * are not same with selected block/blocks
  //  * then update the URL with selected values
  //  */
  // else if (blocks && blocks.length && (urlSearch.current
  //   && !urlSearch.current.includes(`blockId=${blocks.join(',')}`)
  //   && search && !search.includes(`blockId=${blocks.join(',')}`))) updateURL.current = true;
  // // Default will be true
  // else updateURL.current = true;

  // function getBlocksFromUrl() {
  //   const params = new URLSearchParams(urlSearch.current);
  //   const blockIdParam = params.get('blockId') || '';
  //   const blockIds = blockIdParam.split(',').filter((d) => d.length > 0).map((d) => Number(d));
  //   if (blockIds && blockIds.length > 0) return blockIds;
  //   return undefined;
  // }

  // useEffect(() => {
  //   if (urlSearch.current.includes('blockId')) updateURL.current = false;
  //   const urlBlockIds = getBlocksFromUrl();
  //   if (urlBlockIds && urlBlockIds.length) dispatch(setBlocks(urlBlockIds));
  // }, [dispatch]);

  function getDateRangeFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');
    if (startDateParam && endDateParam) {
      return [moment(startDateParam), moment(endDateParam)];
    }
    return undefined;
  }

  function getIrrigationTypesFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    const irrigationTypesParam = params.get('irrigationTypes');
    const irrigationParams = irrigationTypesParam ? irrigationTypesParam.split(',') : undefined;
    if (irrigationParams) return irrigationParams;
    return undefined;
  }

  function getRadioOptionFromUrl() {
    const params = new URLSearchParams(urlSearch.current);
    return params.get('radioOption');
  }

  const now = moment();
  const defaultStateValue = [
    moment(now.startOf('week').format()),
    moment(now.endOf('week').format())];
  // TODO: update this to by key, not display name
  const [radioOption, setRadioOption] = useState(() => getRadioOptionFromUrl() || 'week');
  const [irrigationTypes, setIrrigationTypes] = useState(() => getIrrigationTypesFromUrl()
    || ['scheduled']);
  const [dateRange, setDateRange] = useState(() => getDateRangeFromUrl() || defaultStateValue);
  const [modalData, setModalData] = useState(null);
  const changedObj = { irrigationTypes, radioOption, dateRange };
  const url = useSetURLFromState(urlSearch, search, pathname, changedObj);

  // This will be removed after testing
  // useEffect(() => {
  //   function getParamStr() {
  //     const startDate = dateRange.length ? dateRange[0] : null;
  //     const endDate = dateRange.length ? dateRange[1] : null;
  //     const params = {};

  //     // create sensor params
  //     if (blocks && blocks.length) {
  //       params.blockId = blocks.join(',');
  //     }

  //     if (irrigationTypes) params.irrigationTypes = irrigationTypes.join(',');
  //     if (startDate) params.startDate = startDate.toISOString();
  //     if (endDate) params.endDate = endDate.toISOString();
  //     if (radioOption) params.radioOption = radioOption;

  //     let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
  //     if (paramStr.length) paramStr = `?${paramStr}`;
  //     return paramStr;
  //   }
  //   if (updateURL.current) {
  //     // Check the blocks from state with existing URL.
  //     // trigger the API call when blocks are not same
  //     if (blocks && (blocks.length !== (getBlocksFromUrl() || []).length
  //      || !urlSearch.current.includes(`blockId=${blocks.join(',')}`))) {
  //       refresh.current = true;
  //     }
  //     const paramStr = getParamStr();
  //     history.replace({
  //       pathname,
  //       search: paramStr,
  //     }, { showBack: false });
  //   }
  // }, [history, blocks, irrigationTypes, radioOption, dateRange, pathname]);

  function showCreateSchedule({
    blockId,
    startDate,
    endDate,
    scheduleId,
  }) {
    // TODO: fix the magic number below
    if (window.innerWidth > 599) { // show modal only on laptop and desktop screens
      setModalData({
        blocks: blockId,
        dateRange: [moment(startDate), moment(endDate)],
        scheduleId,
        createNew: !scheduleId,
      });
    } else if (scheduleId) {
      const link = `/schedule/edit?blockId=${blockId}`
        + `&scheduleId=${scheduleId}`;
      history.push(link, { showBack: true });
    } else {
      const link = `/schedule/new?blockId=${blockId}`
        + `&startDate=${startDate}`;
      history.push(link, { showBack: true });
    }
  }

  function onClickChart(obj) {
    refresh.current = false;
    const { startDate } = obj;
    const endDate = moment(startDate).add(12, 'hours').format('YYYY-MM-DD HH:mm');
    return showCreateSchedule({
      blockId: obj.id || obj.blockId,
      startDate: moment(startDate).format('YYYY-MM-DD HH:mm'),
      endDate,
      scheduleId: obj.scheduleId,
    });
  }

  function onClickCreateSchedule() {
    const startDate = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm');
    const endDate = moment(startDate).add(12, 'hours').format('YYYY-MM-DD HH:mm');
    return showCreateSchedule({
      blockId: blocks.length && blocks[0],
      startDate,
      endDate,
      scheduleId: null,
    });
  }

  function renderDateRangeSelect() {
    if (!blocks || (blocks && !blocks.length)) return null;

    function handleOnClick(event) {
      const start = moment(dateRange[0]).format();
      const end = moment(dateRange[1]).format();
      let diff = moment(end).diff(moment(start));
      diff += 1000;
      if (event === 'previous' && radioOption !== 'month') {
        setDateRange([moment(start).subtract(diff), moment(end).subtract(diff)]);
      } else if (event === 'next' && radioOption !== 'month') {
        setDateRange([moment(start).add(diff), moment(end).add(diff)]);
      } else if (event === 'previous' && radioOption === 'month') {
        const startMonth = moment(dateRange[0]).subtract(1, 'months')
          .startOf('month').format('YYYY-MM-DD HH:mm');
        const endMonth = moment(dateRange[0]).subtract(1, 'months')
          .endOf('month').format('YYYY-MM-DD HH:mm');
        setDateRange([moment(startMonth),
          moment(endMonth)]);
      } else if (event === 'next' && radioOption === 'month') {
        const startMonth = moment(dateRange[0]).add(1, 'months')
          .startOf('month').format('YYYY-MM-DD HH:mm');
        const endMonth = moment(dateRange[0]).add(1, 'months')
          .endOf('month').format('YYYY-MM-DD HH:mm');
        setDateRange([moment(startMonth),
          moment(endMonth)]);
      }
    }
    return (
      <div className="schedule-date-picker">
        <span style={{ marginRight: '2vw' }}>
          <Button
            className="previous-date-button"
            onClick={() => handleOnClick('previous')}
          >
            <ArrowLeftOutlined />
            <span className="previous-date-icon">Previous</span>
          </Button>
        </span>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          onChange={(d) => {
            if (d) setDateRange(d);
            else {
              setRadioOption('week');
              setDateRange(defaultStateValue);
            }
          }}
          value={dateRange}
          format="YYYY-MM-DD HH:mm"
          defaultValue={dateRange}
          onOk={null}
          style={{ width: '100%' }}
        />
        <span style={{ marginLeft: '2vw' }}>
          <Button
            className="next-date-button"
            onClick={() => handleOnClick('next')}
          >
            <span className="next-date-icon">Next</span>
            <ArrowRightOutlined />
          </Button>
        </span>
      </div>
    );
  }

  function renderRadioButton() {
    if (!blocks || (blocks && !blocks.length)) return null;
    function radioOnChange(event) {
      setRadioOption(event.target.value);
      if (event.target.value === 'today') {
        setDateRange([moment().startOf('day'),
          moment().endOf('day')]);
      } else if (event.target.value === 'week') {
        setDateRange([moment().startOf('week'),
          moment().endOf('week')]);
      } else if (event.target.value === 'two-week') {
        setDateRange([moment().startOf('week'),
          moment().endOf('week').add(1, 'weeks')]);
      } else if (event.target.value === 'month') {
        setDateRange([moment().startOf('month'),
          moment().endOf('month')]);
      }
    }

    return (
      <Radio.Group
        onChange={radioOnChange}
        defaultValue="week"
        value={radioOption}
      >
        <Radio.Button value="today">{t('Today')}</Radio.Button>
        <Radio.Button value="week">{t('This Week')}</Radio.Button>
        <Radio.Button value="two-week">{t('Two Weeks')}</Radio.Button>
        <Radio.Button value="month">{t('Month')}</Radio.Button>
      </Radio.Group>
    );
  }

  function onCheckboxChecked(d) {
    let valueToset;
    if (irrigationTypes.indexOf(d.target.value) !== -1) {
      valueToset = irrigationTypes.filter((e) => e !== d.target.value);
    } else {
      valueToset = irrigationTypes.concat(d.target.value);
    }
    setIrrigationTypes(valueToset.sort());
  }

  function renderIrrigationTypeSelect() {
    if (!blocks || (blocks && !blocks.length)) return null;
    return (
      <section className="checkbox">
        <span className="left-half">
          <Checkbox
            value="recommended"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('recommended') : false}
          >
            <Tag color="rgb(193, 218, 214)" style={{ cursor: 'pointer' }}>{t('Recommended')}</Tag>
          </Checkbox>
          <Checkbox
            value="scheduled"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('scheduled') : false}
          >
            <Tag color="lightblue" style={{ cursor: 'pointer' }}>{t('Scheduled')}</Tag>
          </Checkbox>
          <Checkbox
            value="past"
            onChange={(d) => onCheckboxChecked(d)}
            checked={irrigationTypes ? irrigationTypes.includes('past') : false}
          >
            <Tag color="rgb(232, 208, 169)" style={{ cursor: 'pointer' }}>{t('Past')}</Tag>
          </Checkbox>
        </span>
        <div className="right-half">
          {renderRadioButton()}
        </div>
      </section>
    );
  }

  function getHeaderExtra() {
    if (!blocks || (blocks && !blocks.length)) return [];
    return [
      <Button
        key={0}
        onClick={() => onClickCreateSchedule()}
        type="primary"
      >
        <span className="headerButtonText">{t('Create')}</span>
        <PlusOutlined />
      </Button>,
      <Button
        key={1}
        onClick={() => {
          dispatch(setSelectedBlocks([...blocks].sort()));
          refresh.current = true;
        }}
      >
        <span className="headerButtonText">{t('Refresh')}</span>
        <ReloadOutlined />
      </Button>,
    ];
  }

  return (
    <div className="single-column-page" id="SchedulePageMobile">
      <div className="padded-margin">
        <PageHeader
          title={t('Irrigation Schedule')}
          onBack={() => history.goBack()}
          backIcon={
            state && state.showBack
              ? (
                <Button className="back-schedule-button">
                  <ArrowLeftOutlined />
                  <span className="back-schedule-text">{t('Go Back')}</span>
                </Button>
              ) : null
            }
          extra={getHeaderExtra()}
        />
        <a href="https://app.farmx.co/home/schedule">
          <Alert
            message="This is the new schedule page. Click here to view the old schedule page"
          />
        </a>
        <Divider />
        <div style={{ fontWeight: '500', paddingLeft: '1px' }}>{`${t('Irrigation Blocks')}:`}</div>
        <BlockSelect
          placeholder={t('Select Blocks To Irrigate')}
          mode="multiple"
          onChange={(d) => {
            dispatch(setUrlLoad(false));
            dispatch(setBlocks(d));
            refresh.current = true;
          }}
          value={blocks}
        />
        <Divider dashed />
        {renderDateRangeSelect()}
        {renderIrrigationTypeSelect()}
      </div>
      {blocks && blocks.length > 0
        ? (
          <div className="schedule-chart-outer">
            <div className="schedule-chart-inner">
              <IrrigationSchedule
                blocks={blocks}
                dates={dateRange}
                irrigationTypes={irrigationTypes}
                onClickChart={onClickChart}
                callApi={refresh.current}
                onDataRetrieval={() => {
                  refresh.current = false;
                }}
              />
            </div>
          </div>
        ) : (
          null
        )}
      {modalData ? (
        <Modal
          title={t(`${modalData.createNew ? 'New' : 'Modify'} Irrigation Schedule`)}
          centered
          visible={!!modalData}
          closable
          onCancel={() => {
            setModalData(null);
          }}
          maskClosable={false}
          destroyOnClose
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          {modalData.createNew
            ? (
              <ScheduleCreateForm
                blocks={[modalData.blocks]}
                dateRange={modalData.dateRange}
                onSubmit={(d) => {
                  if (d.status && d.status === 'Created') {
                    refresh.current = true;
                    setModalData(null);
                    dispatch(setSelectedBlocks([...blocks]));
                  }
                }}
              />
            ) : (
              <ScheduleUpdateForm
                onCancel={() => {
                  setModalData(null);
                }}
                blockId={modalData.blocks}
                scheduleId={modalData.scheduleId}
                onSubmit={(d) => {
                  if (d.status && d.status === 'Created') {
                    refresh.current = true;
                    setModalData(null);
                    dispatch(setSelectedBlocks([...blocks]));
                  }
                }}
              />
            )}
        </Modal>
      ) : null}
    </div>
  );
}

export default IrrigationSchedulePage;
