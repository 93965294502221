import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { CollapsableSidebar, EntityList } from 'farmx-web-ui';
import './Sidebar.css';

const {
  loadFarmData,
  setEntitySidebarCollapseState,
  setBlocks, setRanch, setUrlLoad,
} = actions;

const {
  selectAllEntities,
  selectAllBlocks,
  selectAllRanches,
  getSelectedBlock,
  getSelectedRanch,
  getSelectedRanches,
} = selectors;

function EntitySidebar(props) {
  const {
    onCollapse,
    collapsed,
  } = props;
  // State to maintain search string
  const [searchOption, setSearchOption] = useState('');
  const [collapseState, setCollapseState] = useState([]); // for collapsible state
  const dispatch = useDispatch();

  // Load all entities, blocks and ranches
  useEffect(() => {
    setTimeout(() => dispatch(loadFarmData()), 0);
  }, [dispatch]);

  // Set EntitySidebarCollapseState
  useEffect(() => {
    dispatch(setEntitySidebarCollapseState(collapsed));
  }, [collapsed, dispatch]);

  // Get all entities, blocks and ranches
  const entity = useSelector(selectAllEntities);
  const ranch = useSelector(selectAllRanches);
  const block = useSelector(selectAllBlocks);

  // Get selected ranch and block
  const selectedRanch = useSelector((state) => getSelectedRanch(state));
  const selectedBlock = useSelector((state) => getSelectedBlock(state));

  const ranchVal = useSelector(getSelectedRanches).payload;

  useEffect(() => {
    if (ranchVal && ranchVal.length) setCollapseState(ranchVal);
  }, [ranchVal]);

  // callback function to set values to redux-state
  function onChangeToSetState(d) {
    dispatch(setUrlLoad(false));
    if (d && d.ranch) {
      dispatch(setRanch(Number(d.ranch)));
    } else if (d && d.block) {
      dispatch(setBlocks([Number(d.block)]));
    } else {
      setCollapseState(d);
    }
  }

  // Function to process data and return array of data objects
  function processData(entityData, blockData, ranchData) {
    const resultData = [];
    // Get ranchIds, BlockIds and entityIds from blockData
    const entityIdFromBlock = blockData.map((d) => (d.ranch
      && d.ranch.entity && d.ranch.entity.id));
    const ranchIdFromBlock = blockData.map((d) => (d.ranch && d.ranch.id));
    const blockIdFromBlock = blockData.map((d) => (d && d.id));

    // Get ranchIds, BlockIds and entityIds from ranchData
    const entityIdFromRanch = ranchData.map((d) => (d.entity && d.entity.id));
    const ranchIdFromRanch = ranchData.map((d) => (d && d.id));
    const BlockIdFromRanch = [];
    ranchData.forEach((d) => {
      const bid = block.filter((b) => b.ranch && b.ranch.id === d.id);
      if (bid.length) {
        bid.forEach(((bi) => BlockIdFromRanch.push(bi.id)));
      }
    });

    // Get ranchIds, BlockIds and entityIds from entityData
    const entityIdFromEntity = entityData.map((d) => (d && d.id));
    const ranchIdFromEntity = [];
    entityData.forEach((d) => {
      const rid = ranch.filter((r) => r.entity && r.entity.id === d.id);
      if (rid.length) {
        rid.forEach(((ri) => ranchIdFromEntity.push(ri.id)));
      }
    });
    const BlockIdFromEntity = [];
    entityData.forEach((d) => {
      const bid = block.filter((b) => b.ranch && b.ranch.entity && b.ranch.entity.id === d.id);
      if (bid.length) {
        bid.forEach(((bi) => BlockIdFromEntity.push(bi.id)));
      }
    });

    // Merge all entityIds and get unique list from that
    // Similarly it does the same process for blockIds and ranchIds also
    const allEnitity = entityIdFromBlock.concat(entityIdFromRanch
      .concat(entityIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);
    const allRanch = ranchIdFromBlock.concat(ranchIdFromRanch
      .concat(ranchIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);
    const allBlock = blockIdFromBlock.concat(BlockIdFromRanch
      .concat(BlockIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);

    // Filter the data objects based on merged ids
    const filteredEntity = entity.filter((d) => allEnitity.indexOf(d.id) >= 0);
    const filteredRanch = ranch.filter((d) => (allRanch.indexOf(d.id) >= 0)
      && (allEnitity.indexOf(d.entity && d.entity.id) >= 0));
    const filteredBlock = block.filter((d) => (allBlock.indexOf(d.id) >= 0)
      && (allRanch.indexOf(d.ranch && d.ranch.id) >= 0));

    // Get data objects for each entity and push to resultData
    filteredEntity.forEach((d) => {
      const obj = {
        id: d.id,
        name: d.name,
        ranches: [],
        isLoading: true,
      };
      const ranchFromFilteredRanch = filteredRanch.filter((e) => e.entity && e.entity.id === d.id);
      if (ranchFromFilteredRanch.length) {
        ranchFromFilteredRanch.forEach((r) => {
          const rObj = {
            id: r.id,
            name: r.name,
            blocks: [],
            isLoading: true,
          };
          const blockFromFilteredBlock = filteredBlock.filter((e) => e.ranch
            && e.ranch.id === r.id);
          if (blockFromFilteredBlock.length) {
            blockFromFilteredBlock.forEach((b, k) => {
              const bObj = {
                id: b.id,
                name: b.name,
                crop: b.crop,
              };
              rObj.isLoading = false;
              rObj.blocks.push(bObj);
              if (blockFromFilteredBlock.length === (k + 1)) {
                obj.isLoading = false;
                obj.ranches.push(rObj);
              }
            });
          } else {
            obj.isLoading = false;
            obj.ranches.push(rObj);
            if (block.length) rObj.isLoading = null;
          }
        });
      } else if (ranch.length) obj.isLoading = null;
      resultData.push(obj);
    });
    return resultData;
  }

  // Based on search string filter the data
  let entities = [];
  if (entity.length) {
    const entityData = entity.filter((d) => (d && d.name.toLowerCase()
      .includes(searchOption.toLowerCase())));
    if (entityData.length) entities = entityData;
  }
  let ranches = [];
  if (ranch.length) {
    const ranchData = ranch.filter((d) => (d && d.name.toLowerCase()
      .includes(searchOption.toLowerCase())));
    if (ranchData.length) ranches = ranchData;
  }
  let blocks = [];
  if (block.length) {
    const blockData = block.filter((d) => (d && d.name.toLowerCase()
      .includes(searchOption.toLowerCase())));
    if (blockData.length) blocks = blockData;
  }

  // This finalData will be passed as props to EntityList
  const finalData = processData(entities, blocks, ranches);

  return (
    <CollapsableSidebar
      theme="light"
      onCollapse={onCollapse}
      collapsed={collapsed}
    >
      <div className="nav-menu">
        <div className="nav-menu-search-box">
          <Input
            onChange={(d) => setSearchOption(d.target.value)}
            placeholder="Search"
            defaultValue={searchOption}
          />
        </div>
        <div className="nav-menu-content">
          <EntityList
            data={finalData}
            onChange={onChangeToSetState}
            selected={{
              ranch: selectedRanch.payload || [],
              block: selectedBlock.payload || [],
            }}
            activeSelections={collapseState}
          />
        </div>
      </div>
    </CollapsableSidebar>
  );
}

EntitySidebar.propTypes = {
  onCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
};

EntitySidebar.defaultProps = {
  onCollapse: undefined,
  collapsed: false,
};

export default EntitySidebar;
