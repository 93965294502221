import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-US', // use en if detected lng is not available
    debug: false,
    backend: {
      projectId: '81f3dbc8-2534-4c93-8a7a-e58df7ffe816',
      apiKey: '73a09d9c-dc1d-4fb4-887f-344f569eac65',
      referenceLng: 'en-US',
    },
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
