import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button,
} from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ScheduleUpdateForm } from 'farmx-web-ui';
import './ScheduleCreatePage.css';

function ScheduleModifyPage(props) {
  const { history, location } = props;
  const { state } = location;
  const [block, setBlock] = useState(0);
  const [scheduleId, setScheduleId] = useState(0);
  const [paramDateRange, setParamDateRange] = useState([]);

  const URL = useLocation();
  let propBlockId = 0;
  let propDateRange = [];
  if (URL) {
    if (URL.search) {
      const params = new URLSearchParams(URL.search);
      const blockId = params.get('blockId');
      const startDateParam = params.get('startDate');
      const endDateParam = startDateParam
        ? moment(startDateParam).add(12, 'hours')
        : null;
      if (startDateParam && endDateParam) {
        propDateRange = [moment(startDateParam), endDateParam];
      }
      if (blockId) {
        if (blockId.length > 0) propBlockId = Number(blockId);
      }
    }
  }
  useEffect(() => {
    if (URL) {
      if (URL.search) {
        const params = new URLSearchParams(URL.search);
        const blockId = params.get('blockId');
        const schId = params.get('scheduleId');
        const startDateParam = params.get('startDate');
        const endDateParam = startDateParam
          ? moment(startDateParam).add(12, 'hours')
          : null;
        if (startDateParam && endDateParam) {
          setParamDateRange([moment(startDateParam), endDateParam]);
        }
        if (blockId) setBlock(Number(blockId));
        if (schId) setScheduleId(Number(schId));
      }
    }
  }, [URL]);

  return (
    <div className="schedule-form-container">
      <div className="formHeading">
        <PageHeader
          title="Modify Irrigation Schedule"
          onBack={() => history.goBack()}
          backIcon={
            state && state.showBack
              ? (
                <Button className="back-form-button">
                  <ArrowLeftOutlined />
                  <span className="back-form-text">Go Back</span>
                </Button>
              ) : <div className="back-form-button"><span className="back-form-text" /></div>
            }
        />
        <div>&nbsp;</div>
      </div>
      <ScheduleUpdateForm
        blockId={block || propBlockId}
        scheduleId={scheduleId}
        dateRange={paramDateRange.length > 0 ? paramDateRange : propDateRange}
      />
    </div>
  );
}

ScheduleModifyPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      showBack: PropTypes.bool,
    }),
  }),
};

ScheduleModifyPage.defaultProps = {
  history: {
    goBack: () => {},
    push: () => {},
  },
  location: {
    state: {
      showBack: false,
    },
  },
};

export default ScheduleModifyPage;
