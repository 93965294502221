import React from 'react';
import { Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

export default function NotificationSettingsPage() {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={4}>{t('Notification Settings')}</Typography.Title>
      <Divider />
      <p>Coming soon!</p>
    </>
  );
}
