import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { PageHeader, Button, Divider } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RanchSelect, ControlSummaryRefreshContext } from 'farmx-web-ui';
import { createBrowserHistory } from 'history';
import IrrigationControlSummary from './IrrigationControlSummary';
import './IrrigationControlSummary.css';

const {
  setRanch, setUrlLoad,
} = actions;

const {
  getSelectedRanch,
} = selectors;

function IrrigationControlSummaryPage(props) {
  const { history } = props;
  const selectedRanchId = useSelector((state) => getSelectedRanch(state));
  const ranchIds = selectedRanchId.payload && selectedRanchId.payload.length
    ? selectedRanchId.payload.map((d) => Number(d)) : [];
  const ranchId = ranchIds[0];
  const [lastUpdated, setLastUpdated] = useState(null);
  const REFRESH_INTERVAL = 1000 * 60;
  const refreshStatusInterval = useRef(null);
  const refreshStatusRanchId = useRef(ranchId);
  const refreshId = useRef(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobileScreen = window.innerWidth < 599;

  function refreshUntilUnmount() {
    refreshId.current += 1;
    refreshStatusInterval.current = setInterval(() => {
      refreshId.current += 1;
      if (refreshStatusRanchId.current) {
        setLastUpdated(moment().format('YYYY-MM-DD HH:mm:ss'));
      }
    }, REFRESH_INTERVAL);
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  const URL = useLocation();
  if (URL.search && URL.search.includes('ranchId')) {
    const params = new URLSearchParams(URL.search);
    const ranchIdParam = params.get('ranchId');
    if (Number(ranchIdParam) && !refreshStatusInterval.current) {
      refreshStatusRanchId.current = Number(ranchIdParam);
      refreshUntilUnmount();
    }
  }

  // Clear the interval when component unmounts
  useEffect(() => () => {
    clearRefreshInterval();
  }, []);

  function getHeaderExtra() {
    if (!ranchId) return [];
    return [
      <Button
        className="refreshButton"
        key={0}
        onClick={() => {
          if (ranchId) {
            clearRefreshInterval();
            refreshUntilUnmount();
            setLastUpdated(moment().format('YYYY-MM-DD HH:mm:ss'));
          }
        }}
      >
        <span className="headerButtonText">Refresh</span>
        <ReloadOutlined />
      </Button>,
    ];
  }

  return (
    <ControlSummaryRefreshContext.Provider value={refreshId.current}>
      <div className="controlContainer">
        <div className="single-column-page padded">
          <PageHeader
            title={t('Irrigation Control Summary')}
            extra={getHeaderExtra()}
          />
          <Divider />
          <div>
            {lastUpdated
              ? (
                <div className="lastupdate">
                  <span>Last Updated : </span>
                  <div>&nbsp;</div>
                  <span>{lastUpdated}</span>
                </div>
              )
              : null }
          </div>
          <div className={mobileScreen ? 'mobilescreen' : 'ranch'}>
            <h3>Select Ranch</h3>
            <RanchSelect
              onChange={(d) => {
                if (d === undefined) {
                  dispatch(setRanch());
                  dispatch(setUrlLoad(false));
                  history.push('/control');
                  refreshStatusRanchId.current = null;
                  clearRefreshInterval();
                  setLastUpdated(null);
                }
              }}
              onSelect={(d) => {
                dispatch(setRanch(d));
                refreshStatusRanchId.current = d;
                clearRefreshInterval();
                if (!refreshStatusInterval.current) {
                  refreshUntilUnmount();
                  setLastUpdated(moment().format('YYYY-MM-DD HH:mm:ss'));
                }
              }}
              value={ranchIds}
            />
          </div>
          {ranchId
            ? (
              <IrrigationControlSummary
                ranchId={ranchId}
                onClick={clearRefreshInterval}
              />
            ) : ''}
        </div>
      </div>
    </ControlSummaryRefreshContext.Provider>
  );
}

IrrigationControlSummaryPage.propTypes = {
  history: PropTypes.shape(PropTypes.any.isRequired),
};

IrrigationControlSummaryPage.defaultProps = {
  history: createBrowserHistory(),
};

export default IrrigationControlSummaryPage;
