import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { LoginPage, LogoutRedirect } from '../authentication';
import Main from './Main';
import './app.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route component={LoginPage} exact path="/login" />
        <Route component={LogoutRedirect} exact path="/logout" />
        <Route component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
