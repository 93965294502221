import { SettingsPage } from '../settings';

import {
  ScheduleCreatePage,
  SchedulePage,
  ScheduleUpdatePage,
} from '../schedule';

import {
  IrrigationControlSummary,
} from '../control';

import {
  EventsPage,
} from '../stream';

import {
  Dashboard,
} from '../dashboard';

import { MapByRanchIdPage } from '../map';

export const ROUTE_KEYS = {
  ROOT: '/',
  CONTROL: '/control',
  SCHEDULE: '/schedule',
  SCHEDULE_CREATE: '/schedule/new',
  SCHEDULE_EDIT: '/schedule/edit',
  SETTINGS_PAGE: '/settings',
  MAP_BY_RANCH_ID: '/map/ranch/:ranch_id',
  EVENTS: '/events',
  DASHBOARD: '/dashboard',
};

export const ROUTES = {
  SETTINGS_PAGE: {
    component: SettingsPage,
    exact: false,
    key: ROUTE_KEYS.SETTINGS_PAGE,
    path: ROUTE_KEYS.SETTINGS_PAGE,
  },
  SCHEDULE: {
    component: SchedulePage,
    exact: true,
    key: ROUTE_KEYS.SCHEDULE,
    path: ROUTE_KEYS.SCHEDULE,
  },
  SCHEDULE_CREATE: {
    component: ScheduleCreatePage,
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_CREATE,
    path: ROUTE_KEYS.SCHEDULE_CREATE,
  },
  SCHEDULE_EDIT: {
    component: ScheduleUpdatePage,
    exact: true,
    key: ROUTE_KEYS.SCHEDULE_EDIT,
    path: ROUTE_KEYS.SCHEDULE_EDIT,
  },
  CONTROL: {
    component: IrrigationControlSummary,
    exact: true,
    key: ROUTE_KEYS.CONTROL,
    path: ROUTE_KEYS.CONTROL,
  },
  MAP_BY_RANCH_ID: {
    component: MapByRanchIdPage,
    exact: true,
    key: ROUTE_KEYS.MAP_BY_RANCH_ID,
    path: ROUTE_KEYS.MAP_BY_RANCH_ID,
  },
  EVENTS: {
    component: EventsPage,
    exact: true,
    key: ROUTE_KEYS.EVENTS,
    path: ROUTE_KEYS.EVENTS,
  },
  DASHBOARD: {
    component: Dashboard,
    exact: true,
    key: ROUTE_KEYS.DASHBOARD,
    path: ROUTE_KEYS.DASHBOARD,
  },
};
